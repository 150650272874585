var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  }, [_c('path', {
    attrs: {
      "d": "M32 16c0-8.837-7.163-16-16-16S0 7.163 0 16s7.163 16 16 16 16-7.163 16-16Z",
      "fill": "#07F"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M8 15.667c0-3.614 0-5.421 1.123-6.544C10.246 8 12.053 8 15.667 8h.666c3.614 0 5.422 0 6.544 1.123C24 10.246 24 12.053 24 15.667v.666c0 3.614 0 5.422-1.123 6.544C21.755 24 19.947 24 16.333 24h-.666c-3.614 0-5.421 0-6.544-1.123C8 21.755 8 19.947 8 16.333v-.666Z",
      "fill": "#fff"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12.334 13h-1.167c-.334 0-.4.157-.4.33 0 .309.395 1.842 1.842 3.869.964 1.384 2.323 2.134 3.559 2.134.742 0 .834-.166.834-.453v-1.047c0-.333.07-.4.305-.4.173 0 .47.087 1.162.754.791.791.921 1.146 1.366 1.146h1.167c.334 0 .5-.166.404-.495-.105-.328-.483-.804-.984-1.367-.272-.322-.68-.668-.803-.84-.174-.223-.124-.322 0-.52 0 0 1.421-2.002 1.57-2.682.074-.247 0-.429-.353-.429h-1.167a.504.504 0 0 0-.508.33s-.593 1.446-1.434 2.385c-.272.272-.396.359-.544.359-.074 0-.182-.087-.182-.334V13.43c0-.297-.086-.429-.333-.429h-1.834c-.185 0-.297.138-.297.268 0 .281.42.346.464 1.137v1.718c0 .377-.068.445-.216.445-.396 0-1.358-1.452-1.929-3.114-.112-.324-.224-.454-.522-.454Z",
      "fill": "#07F"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }